<template>
  <div class="d-flex">
    <CompanyCard />
    <CompanyCard />
    <CompanyCard />
  </div>
</template>

<script>
import CompanyCard from "./components/CompanyCard.vue";
export default {
  data() {
    return {};
  },
  components: {
    CompanyCard,
  },
  methods: {},
  mounted() {},
};
</script>
